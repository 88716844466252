.intro-mobile-container {
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
  background-color: rgba(246, 246, 246, 1);
  .intro-mobile-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .intro-mobile-title {
      font-size: 30px;
      font-weight: bold;
    }
    .intro-mobile-desc {
      padding: 20px;
    }
  }

  .img-section {
    position: relative;
    height: 550px;
    .image-mobile- {
      &1,
      &2,
      &3,
      &4 {
        position: absolute;
      }

      &2 {
        left: 3%;
        top: 30%;
      }

      &3 {
        right: 1%;
        top: 5%;
      }

      &4 {
        left: 50%;
        transform: translateX(-50%);
        top: 55%;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .intro-mobile-container {
    .img-section {
      transform: scale(0.95);
      transform-origin: center center;

      .image-mobile- {
        &1 {
          left: -3%;
        }
        &2 {
          left: -1%;
          top: 30%;
        }

        &3 {
          right: -2%;
          top: 5%;
        }
      }
    }
  }
}
