@import "./font.scss";

.application-container {
  height: 900px;
  width: 1440px;
  padding: 90px 160px;
  position: relative;

  #app-bg {
    position: absolute;
    bottom: 20px;
    left: 0;
    height: 430px;
    z-index: -1;
  }

  #dots {
    position: absolute;
    top: 20%;
    right: 4%;
    height: 430px;
    z-index: -1;
    height: 200px;
  }
  .title {
    font-size: $medium-font;
    font-weight: bold;
    position: relative;
  }

  .background-line {
    width: 293px;
    height: 20px;
    // margin-left: 127px;
    background-color: rgba(255, 218, 67, 0.8);
    position: absolute;
    bottom: 18%;
    left: 3%;
    z-index: -1;
  }

  .card-list {
    display: flex;
    justify-content: space-between;
    margin-top: 7%;
  }
}

// 手机版样式
@media screen and (max-width: 425px) {
  .application-container {
    width: 100vw;
    padding: 30px 10px;
    height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    #app-bg,
    #dots {
      display: none;
    }

    .title {
      font-size: 24px;
      position: relative;
      .background-line {
        width: 120px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .card-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: -7%;
    }
  }
}
