@import "./font.scss";
.function-container {
  height: 900px;
  width: 1440px;
  padding-left: 160px;
  padding-right: 160px;
  background-color: rgba(246, 246, 246, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  .left-section {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    .function-background {
      width: 536px;
      height: 138px;
      position: absolute;
      top: 0%;
      color: rgba(255, 218, 67, 1);
      font-size: 137px;
      opacity: 0.26;
      font-weight: bolder;
      z-index: -1;
    }

    .function-title {
      width: 604px;
      height: 148px;
      font-size: $large-font;
      font-weight: bold;
    }

    .function-explain {
      margin-top: 4em;
      width: 519px;
      font-size: 18px;
    }
  }

  .function-image {
    width: 462px;
    height: 446px;
  }

  .circle {
    position: absolute;
    z-index: -1;
    width: 381px;
    height: 381px;
    opacity: 0.41;
    background-color: rgba(255, 218, 67, 1);
    border-radius: 50%;
    right: 80px;
    bottom: 122px;
  }
}

// 手机版样式
@media screen and (max-width: 425px) {
  .function-container {
    height: unset;
    flex-direction: column;
    padding: 50px 0 70px 0;
    width: 100vw;

    .left-section {
      .function-background {
        width: 343px;
        height: 88px;
        top: 0%;
        // left: 160px;
        color: rgba(255, 218, 67, 1);
        font-size: 80px;
      }

      .function-title {
        width: fit-content;
        height: fit-content;
        font-size: 30px;
      }

      .function-explain {
        margin: 30px 0;
        width: 340px;
        font-size: 14px;
      }
    }

    .function-image {
      width: 277px;
      height: 268px;
    }

    .circle {
      width: 269px;
      height: 269px;
      right: 0px;
      top: 280px;
    }
  }
}
