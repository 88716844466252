@import "./color.scss";
@import "./font.scss";

header {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 1440px;
  background-color: $main-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 160px;
  padding-right: 160px;
  height: 80px;

  .logo {
    font-size: $small-font;
    font-weight: bold;
    cursor: pointer;
  }

  .titles {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    padding-right: 30px;
    padding-left: 30px;
    // clear the bottom margin
    p {
      margin-bottom: 0;
      cursor: pointer;
      font-weight: bold;
      padding: 5px 2px;

      &:hover {
        border-bottom: 2px solid black;
      }
    }
  }

  #dropdown-basic {
    background-color: $main-color;
    border-color: white;
    outline: none;
    border-radius: 5px;
  }

  .more {
    display: none;
    cursor: pointer;
  }
}

// 手机版样式
@media screen and (max-width: 425px) {
  header {
    padding: 20px 20px;
    // height: 70px;
    width: 100vw;
    align-items: end;
    z-index: 999;
    height: unset;
    .titles {
      display: none;
    }

    .more {
      display: block;
      width: 36px;
    }
  }

  #nav-container {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: rgba(51, 51, 51, 1);
      font-size: 16px;
      font-family: SourceHanSans;
      height: 50px;
      border-bottom: 1px solid rgba(239, 239, 239, 1);
      font-weight: bold;

      &:hover {
        background-color: rgba(245, 245, 245, 1);
      }

      :first-of-type {
        border-top: 1px solid rgba(239, 239, 239, 1);
      }
    }
  }

  .modal-title {
    width: 94px;
    height: 24px;
    color: rgba(255, 218, 67, 1);
    font-size: 24px;
    font-family: SourceHanSans;
    font-weight: bolder !important;
  }
}
