@import "./color.scss";
@import "./font.scss";
.card-container {
  width: 232px;
  height: 439px;

  .header-top {
    width: 232px;
    height: 120px;
    background: #363433;
    clip-path: polyGon(0 0, 100% 0, 100% 65%, 50% 100%, 0 65%);
    position: relative;
    z-index: 9;
    margin-top: 20px;

    .number {
      color: $main-color;
      font-size: $large-font;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .content {
    transform: translateY(-45px);
    z-index: -1;
    display: flex;
    flex-direction: column;
    padding: 25px;
    justify-content: flex-start;
    padding-top: 60px;

    align-items: center;
    background-color: rgba(243, 242, 242, 1);
    // padding-top: 50px;
    height: 370px;
    text-align: center;

    .name {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;
    }

    .detail {
      font-size: 14px;
    }
  }
  .footer-left {
    transform: translateY(-45px);
    width: 232px;
    height: 50px;
    background-color: rgba(243, 242, 242, 1);
    clip-path: polyGon(0 0, 100% 0, 100% 100%, 50% 0, 0 100%, 0 0);
  }
}

// 手机版样式
@media screen and (max-width: 425px) {
  .card-container {
    margin: 20px 0;
    width: 165px;
    height: 340px;

    .header-top {
      width: 165px;
      height: 75px;
      clip-path: polyGon(0 0, 100% 0, 100% 65%, 50% 95%, 0 65%);

      .number {
        font-size: $medium-font;
      }
    }

    .content {
      height: 270px;
      padding: 10px;
      padding-top: 45px;

      .name {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .detail {
        font-size: 13px;
      }

      .image {
        width: 90px;
        height: 90px;
      }
    }
    .footer-left {
      width: 165px;
      height: 40px;
    }
  }
}
