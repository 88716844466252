@import "./color.scss";
@import "./font.scss";

.contact-container {
  width: 1440px;
  height: 150px;
  // background-color: rgba(246, 246, 246, 1);
  padding: 0px 160px 0 160px;
  position: relative;

  .title {
    font-size: $medium-font;
    font-weight: bold;
    position: relative;
    z-index: 1;
  }

  .background-line {
    position: absolute;
    bottom: 15%;
    left: 3%;
    z-index: -1;

    width: 293px;
    height: 20px;
    background-color: rgba(255, 218, 67, 1);
  }

  .content {
    display: flex;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 700px;
    text-align: center;

    .content-header {
      p:first-of-type {
        font-size: $medium-font;
        font-weight: bold;
      }

      p:last-of-type {
        font-weight: bold;
      }
    }

    .content-form {
      margin-top: 10px;
      width: 684px;
      text-align: left;

      .form-label {
        color: rgba(51, 51, 51, 1);
        font-weight: bold;
      }

      .form-control {
        height: $large-font;
        border-radius: 6px;
        border: 1px solid rgba(208, 208, 208, 1);

        &::-webkit-input-placeholder {
          color: rgba(210, 210, 210, 1);
        }
      }
    }

    #form-button {
      position: absolute;
      left: 50%;
      bottom: -21%;
      transform: translate(-50%, -50%);
      width: 575px;
      height: 56px;
      border-radius: 6px;
      background-color: rgba(255, 218, 67, 1);
      border: 3px solid rgba(51, 51, 51, 1);
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      font-weight: bold;
    }
  }

  footer {
    height: 150px;
    width: 100%;
    background-color: rgba(34, 34, 34, 1);
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 144px;
    z-index: 9;
    span {
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
    }

    .iconList {
      width: 350px;
      color: rgba(255, 255, 255, 1);
      font-size: 12px;

      a {
        text-decoration: none;
        color: inherit;
      }
      img {
        width: 22px;
        height: 22px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// 手机版样式
@media screen and (max-width: 425px) {
  .contact-container {
    height: 100px;
    padding: unset;
    text-align: center;
    padding: 30px 10px;
    width: 100vw;

    .title {
      font-size: $small-font;
      .background-line {
        width: 120px;
        position: absolute;
        left: 55%;
        transform: translateX(-50%);
      }
    }

    .content {
      width: 350px;
      text-align: center;
      top: 45%;
      left: 50%;

      .content-header {
        p:first-of-type {
          font-size: 20px;
        }

        p:last-of-type {
          font-size: 16px;
        }
      }

      .content-form {
        width: 350px;
        // margin-bottom: 60px;
      }

      #form-button {
        width: 350px;
        height: 48px;

        bottom: -18%;
      }
    }

    footer {
      height: 100px;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-around;
      padding: 20px;

      span {
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
      }

      .iconList {
        width: 280px;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;

        a {
          text-decoration: none;
          color: inherit;
        }
        img {
          width: 22px;
          height: 22px;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

// 弹窗样式
.swal2-styled.swal2-confirm,
.confirm-button {
  color: black;
  border-radius: 6px;
  background-color: rgba(255, 218, 67, 1);
  border: 3px solid rgba(51, 51, 51, 1);
}
