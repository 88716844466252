@import "../styles/color.scss";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #333333;
}

// 大屏版样式
@media screen and (min-width: 425px) {
  .App {
    height: 100%;
    width: 100%;
    max-width: 1440px;

    .bg {
      background-color: $main-color;
      width: 100%;
      position: absolute;
      height: 900px;
      z-index: -1;
    }

    .screen {
      display: inline-block;
      width: 1440px; //设计稿的宽度
      height: 900px; //设计稿的高度
      transform-origin: 0 0;
      position: absolute;
      left: 50%;
      // background-color: $main-color;
    }
  }
}

@font-face {
  font-family: SourceHanSans;
  src: url(../../public/SourceHanSans.ttf);
}

body {
  font-family: SourceHanSans !important;
}

.bg-start,
.bg-function,
.bg-advantages,
.bg-contact,
.bg-footer {
  width: 100%;
  position: absolute;
  height: 900px;
  z-index: -1;
  left: 0;
}

.bg-start,
.bg-advantages {
  background-color: $main-color;
}

.bg-function,
.bg-contact {
  background-color: rgba(246, 246, 246, 1);
}

.bg-footer {
  background-color: rgba(34, 34, 34, 1);
}
.link {
  z-index: 100;
  position: fixed;
  bottom: 20vh;
  right: -70px;
  display: block;
  width: 100px;
  padding: 4px 4px 4px 24px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  border-radius: 16px;
  background: url(../../public/images/demo.png) no-repeat 8px/20%, #000;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #fff;
    right: 0;
  }
}
