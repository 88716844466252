@import "./color.scss";
@import "./font.scss";

.start-container {
  height: 900px;
  width: 1440px;
  background-color: $main-color;
  display: flex;
  padding-left: 160px;
  padding-right: 160px;

  .left-section-container {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .left-section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span:nth-of-type(1) {
      font-size: $x-large-font;
      font-weight: bold;
    }

    span:nth-of-type(2) {
      font-size: $medium-font;
      margin-top: 10%;
      margin-bottom: 10%;
      font-weight: 500;
    }

    #start-button {
      background-color: rgba(255, 255, 255, 1);
      width: 176px;
      height: 56px;
      border-radius: 6px;
      font-size: 18px;
      border: 3px solid rgba(51, 51, 51, 1);
      color: rgba(51, 51, 51, 1);
      font-weight: bold;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.2s ease-in-out;
      }
    }

    #book1 {
      position: absolute;
      bottom: 6%;
      width: 120px;
      height: 116.25px;
    }
  }

  .right-section-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 1;
    #book2 {
      position: absolute;
      top: 100px;
      right: 28px;
      width: 120px;
      z-index: -1;
      height: 116.25px;
    }

    #start-pc {
      width: 456px;
      height: 317px;
    }
  }
}

// 手机版样式
@media screen and (max-width: 425px) {
  .start-container {
    width: 100vw;
    height: 617px;
    flex-direction: column;
    justify-content: space-around;
    padding: 80px 0;

    .left-section-container {
      width: 100%;
      text-align: center;
      flex-direction: column;
      justify-content: start;
    }

    .left-section-content {
      align-items: center;
      span:nth-of-type(1) {
        font-size: 26px;
        z-index: 9;
      }

      span:nth-of-type(2) {
        font-size: 20px;
        margin: 20px;
        z-index: 9;
      }

      #book1 {
        display: none;
      }
    }
    .right-section-container {
      width: 100%;
      justify-content: center;

      #book2 {
        top: -120%;
        z-index: -1;
      }

      #start-pc {
        width: 273px;
        height: 195px;
      }
    }
  }
}
