@import "./color.scss";
@import "./font.scss";
.intro-container {
  height: 900px;
  width: 1440px;
  display: flex;
  padding-left: 82px;
  position: relative;
}
.background-layer {
  margin-top: 44px;

  width: 966px;
  height: 613px;
  background-color: $main-color;
  padding-left: 78px;
  position: relative;
  clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
  // background: linear-gradient(-125deg, transparent 30%, $main-color 0) right;
}

.intro-title {
  font-size: $large-font;
  font-weight: bold;
  padding-top: 5%;
  margin-bottom: 2%;

}

.intro-text {
  width: 320px;
height: 201px;
line-height: 31px;
}

.image-1,
.image-2,
.image-3,
.image-4 {
  position: absolute;
}

.image-1 {
  left: 510px;
  top: 95px;
}

.image-2 {
  left: 800px;
  top: 95px;
}

.image-3 {
  transform: translate(1.1);
  left: 175px;
  top: 315px;
}

.image-4 {
  left: 443px;
  top: 350px;

}
