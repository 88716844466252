@import "./font.scss";
@import "./color.scss";

.advantages-container {
  width: 1440px;
  height: 900px;
  background-color: rgba(255, 218, 67, 1);
  padding: 90px 144px 0 144px;
  position: relative;

  .title {
    font-size: $medium-font;
    font-weight: bold;
    position: relative;
    color: white;
    // text-align: center;
    z-index: 1;
  }

  .background-line {
    position: absolute;
    bottom: 15%;
    left: 3%;
    z-index: -1;

    width: 293px;
    height: 20px;
    background-color: rgba(246, 202, 25, 1);
  }

  .top-section {
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    height: 50%;
    align-items: center;
  }
}

.card-container-big,
.card-container-small {
  background-color: #fff;

  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px 40px 20px 40px;
  justify-content: flex-start;

  .card-number {
    font-size: $medium-font;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .card-title {
    font-size: $small-font;
    font-weight: 500;
  }

  .card-desc {
    line-height: 24px;
    color: rgba(102, 102, 102, 1);
    font-size: 16px;
    font-family: SourceHanSans;
    margin: 0;
    text-align: justify;
  }

  .card-icon {
    width: 93.6px;
    height: 93.6px;
    position: absolute;
    top: -47px;
    right: 47px;
  }
}

.card-container-big {
  width: 520px;
  height: 261px;
}

.card-container-small {
  width: 326px;
  height: 287px;

  .card-desc {
    width: 260px;
    height: 107px;
  }

  .card-icon {
    right: 35px;
  }
}

// 手机版样式
@media screen and (max-width: 425px) {
  .advantages-container {
    flex-direction: column;
    padding: 30px 10px 60px 10px;
    height: unset;
    display: flex;
    justify-content: center;
    // text-align: center;
    width: 100%;

    .title {
      font-size: $small-font;
      text-align: center;

      color: rgba(51, 51, 51, 100);
      .background-line {
        width: 120px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .top-section {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }

    .bottom-section {
      flex-direction: column;
      padding: 0 10px;
      height: unset;
    }
  }

  // card styles
  .card-container-big,
  .card-container-small {
    padding: 20px;
    align-items: start;

    .card-title {
      font-size: 20px;
      font-weight: 600;
    }

    .card-desc {
      width: 311px;
      margin-top: 20px;
      color: rgba(102, 102, 102, 1);
    }

    .card-icon {
      width: 65px;
      height: 65px;
      position: absolute;
      top: -30px;
      right: 30px;
    }
  }

  .card-container-big {
    width: 340px;
    height: auto;
    margin-top: 60px;
  }
}
